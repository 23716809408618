import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const ModalWrapper = ({ isOpen, closeModal, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1000,
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: '10px',
          padding: '20px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          maxWidth: '400px',
          width: '90%',
          border: 'none',
          backgroundColor: 'white',
          zIndex: 1000,
        },
      }}
    >
      {children}
    </Modal>
  );
};

export default ModalWrapper;
