import React, { useState } from 'react';
import { updateEtablissement } from '../api';
import './FormStyles.css';

const EtablissementEditForm = ({ etablissement, onClose, onEtablissementUpdated }) => {
  const [nom, setNom] = useState(etablissement.nom);
  const [codeSecret, setCodeSecret] = useState(etablissement.code_secret);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedEtablissement = { ...etablissement, nom, code_secret: codeSecret };
      await updateEtablissement(etablissement.id, updatedEtablissement);
      onEtablissementUpdated(updatedEtablissement);
      onClose();
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'établissement:', error);
    }
  };

  return (
    <div className="form-container">
      <h2>Modifier l'Établissement</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Nom</label>
          <input
            type="text"
            value={nom} 
            onChange={(e) => setNom(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Code Secret</label>
          <input
            type="text"
            value={codeSecret}
            onChange={(e) => setCodeSecret(e.target.value)}
            required
          />
        </div>
        <div className="form-actions">
          <button type="submit" className="header-button add-button">Mettre à jour</button>
          <button type="button" className="header-button cancel-button" onClick={onClose}>Annuler</button>
        </div>
      </form>
    </div>
  );
};

export default EtablissementEditForm;
