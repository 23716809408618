import React, { useState, useEffect } from 'react';
import './FormStyles.css';

const EventForm = ({ etablissementId, event = null, onClose, onSave }) => {
  const [title, setTitle] = useState(event ? event.title : '');
  const [startDate, setStartDate] = useState(event ? event.startDate : '');
  const [startTime, setStartTime] = useState(event ? event.startTime : '');
  const [endDate, setEndDate] = useState(event ? event.endDate : '');
  const [endTime, setEndTime] = useState(event ? event.endTime : '');
  const [addBreak, setAddBreak] = useState(false);
  const [startDate2, setStartDate2] = useState('');
  const [startTime2, setStartTime2] = useState('');
  const [endDate2, setEndDate2] = useState('');
  const [endTime2, setEndTime2] = useState('');

  useEffect(() => {
    if (event) {
      setTitle(event.title);
      setStartDate(event.startDate);
      setStartTime(event.startTime);
      setEndDate(event.endDate);
      setEndTime(event.endTime);
      if (event.startDate2) {
        setAddBreak(true);
        setStartDate2(event.startDate2);
        setStartTime2(event.startTime2);
        setEndDate2(event.endDate2);
        setEndTime2(event.endTime2);
      }
    }
  }, [event]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const eventData = {
      title,
      startDate,
      startTime,
      endDate,
      endTime,
      ...(addBreak && { startDate2, startTime2, endDate2, endTime2 }),
    };

    console.log('Données soumises pour l\'événement:', eventData);

    try {
      if (event) {
        await onSave({ ...event, ...eventData });
      } else {
        await onSave({ ...eventData, etablissementId });
      }
      onClose();
    } catch (error) {
      console.error('Erreur lors de la sauvegarde de l\'événement:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="form-container">
      <h2>{event ? 'Modifier l\'événement' : 'Ajouter un Nouvel Événement'}</h2>
      <div className="form-group">
        <label htmlFor="title">Titre</label>
        <input
          type="text"
          id="title"
          name="title"
          placeholder="Titre"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="startDate">Date de Début</label>
        <input
          type="date"
          id="startDate"
          name="startDate"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="startTime">Heure de Début</label>
        <input
          type="time"
          id="startTime"
          name="startTime"
          value={startTime}
          onChange={(e) => setStartTime(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="endDate">Date de Fin</label>
        <input
          type="date"
          id="endDate"
          name="endDate"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="endTime">Heure de Fin</label>
        <input
          type="time"
          id="endTime"
          name="endTime"
          value={endTime}
          onChange={(e) => setEndTime(e.target.value)}
          required
        />
      </div>

      <div className="form-group checkbox-container">
        <label className="checkbox-label">Ajouter une coupure</label>
        <input
          type="checkbox"
          checked={addBreak}
          onChange={(e) => setAddBreak(e.target.checked)}
        />
      </div>

      {addBreak && (
        <>
          <div className="form-group">
            <label>Deuxième Date de début</label>
            <input
              type="date"
              value={startDate2}
              onChange={(e) => setStartDate2(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Deuxième Heure de début</label>
            <input
              type="time"
              value={startTime2}
              onChange={(e) => setStartTime2(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Deuxième Date de fin</label>
            <input
              type="date"
              value={endDate2}
              onChange={(e) => setEndDate2(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Deuxième Heure de fin</label>
            <input
              type="time"
              value={endTime2}
              onChange={(e) => setEndTime2(e.target.value)}
            />
          </div>
        </>
      )}

      <div className="form-actions">
        <button type="submit" className="header-button add-button">{event ? 'Modifier' : 'Ajouter'}</button>
        <button type="button" className="header-button cancel-button" onClick={onClose}>Annuler</button>
      </div>
    </form>
  );
};

export default EventForm;
