import React, { useState, useEffect } from 'react';
import { registerUser, getAllEtablissements } from '../api';
import './FormStyles.css';

const UserCreateForm = ({ onClose, onUserCreated }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [grade, setGrade] = useState('admin');
  const [etablissementId, setEtablissementId] = useState('');
  const [etablissements, setEtablissements] = useState([]);

  useEffect(() => {
    const fetchEtablissements = async () => {
      try {
        const response = await getAllEtablissements();
        setEtablissements(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des établissements:', error);
      }
    };
    fetchEtablissements();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newUser = { username, password, grade, etablissementId: etablissementId || null };
      await registerUser(newUser);
      onUserCreated(newUser);
      onClose();
    } catch (error) {
      console.error('Erreur lors de la création de l\'utilisateur:', error);
    }
  };

  return (
    <div className="form-container">
      <h2>Ajouter un Utilisateur</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Nom d'utilisateur</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Mot de passe</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Grade</label>
          <select value={grade} onChange={(e) => setGrade(e.target.value)}>
            <option value="admin">Admin</option>
            <option value="superadmin">Superadmin</option>
          </select>
        </div>
        <div className="form-group">
          <label>Établissement</label>
          <select value={etablissementId} onChange={(e) => setEtablissementId(e.target.value)}>
            <option value="">Aucun</option>
            {etablissements.map((etab) => (
              <option key={etab.id} value={etab.id}>
                {etab.nom}
              </option>
            ))}
          </select>
        </div>
        <div className="form-actions">
          <button type="submit" className="header-button add-button">Ajouter</button>
          <button type="button" className="header-button cancel-button" onClick={onClose}>Annuler</button>
        </div>
      </form>
    </div>
  );
};

export default UserCreateForm;
