import React, { useState, useEffect } from 'react';
import { getAllEtablissements, getAllUsers, deleteUser } from '../api';
import EtablissementCreateForm from './EtablissementCreateForm';
import UserCreateForm from './UserCreateForm';
import EtablissementEditForm from './EtablissementEditForm';
import UserEditForm from './UserEditForm';
import ModalWrapper from './ModalWrapper';
import SearchBar from './SearchBar';
import Pagination from './Pagination';
import ConfirmDialog from './ConfirmDialog';
import Notification from './Notification';
import EventList from './EventList';
import './Administration.css';

const Administration = () => {
  const [etablissements, setEtablissements] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [isCreateEtablissementFormVisible, setIsCreateEtablissementFormVisible] = useState(false);
  const [isCreateUserFormVisible, setIsCreateUserFormVisible] = useState(false);
  const [editEtablissement, setEditEtablissement] = useState(null);
  const [editUser, setEditUser] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(null);
  const [notification, setNotification] = useState(null);
  const [selectedEtablissement, setSelectedEtablissement] = useState(null);

  useEffect(() => {
    fetchEtablissements();
    fetchUsers();
  }, []);

  useEffect(() => {
    if (selectedEtablissement) {
      // Charger les événements pour l'établissement sélectionné
    }
  }, [selectedEtablissement]);

  const fetchEtablissements = async () => {
    try {
      const response = await getAllEtablissements();
      setEtablissements(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des établissements:', error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await getAllUsers();
      setUsers(response.data);
      setFilteredUsers(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des utilisateurs:', error);
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      await deleteUser(id);
      setUsers(users.filter(user => user.id !== id));
      setFilteredUsers(filteredUsers.filter(user => user.id !== id));
      setNotification({ message: 'Utilisateur supprimé avec succès.', type: 'success' });
      setConfirmDialog(null); // Fermer la boîte de dialogue de confirmation
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'utilisateur:', error);
      setNotification({ message: 'Erreur lors de la suppression de l\'utilisateur.', type: 'error' });
      setConfirmDialog(null); // Fermer la boîte de dialogue de confirmation même en cas d'erreur
    }
  };

  const handleEtablissementCreated = (newEtablissement) => {
    fetchEtablissements();
    setNotification({ message: 'Établissement créé avec succès.', type: 'success' });
  };

  const handleUserCreated = (newUser) => {
    fetchUsers();
    setNotification({ message: 'Utilisateur créé avec succès.', type: 'success' });
  };

  const handleEtablissementUpdated = (updatedEtablissement) => {
    fetchEtablissements();
    setNotification({ message: 'Établissement mis à jour avec succès.', type: 'success' });
  };

  const handleUserUpdated = (updatedUser) => {
    fetchUsers();
    setNotification({ message: 'Utilisateur mis à jour avec succès.', type: 'success' });
  };

  const handleSearchChange = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filtered = users.filter(user => user.username.toLowerCase().includes(searchValue));
    setFilteredUsers(filtered);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
  const currentUsers = filteredUsers.slice((currentPage - 1) * itemsPerPage, (currentPage) * itemsPerPage);

  const handleEtablissementSelect = (event) => {
    const etablissementId = event.target.value;
    const selected = etablissements.find(etab => etab.id === parseInt(etablissementId, 10));
    setSelectedEtablissement(selected);
  };

  return (
    <div className="administration">
      <h2>Section Administration</h2>
      <div className="actions">
        <button className="header-button add-button" onClick={() => setIsCreateEtablissementFormVisible(true)}>Ajouter un Établissement</button>
        <button className="header-button add-button" onClick={() => setIsCreateUserFormVisible(true)}>Ajouter un Administrateur</button>
      </div>
      <ModalWrapper isOpen={isCreateEtablissementFormVisible} closeModal={() => setIsCreateEtablissementFormVisible(false)}>
        <EtablissementCreateForm
          onClose={() => setIsCreateEtablissementFormVisible(false)}
          onEtablissementCreated={handleEtablissementCreated}
        />
      </ModalWrapper>
      <ModalWrapper isOpen={isCreateUserFormVisible} closeModal={() => setIsCreateUserFormVisible(false)}>
        <UserCreateForm
          onClose={() => setIsCreateUserFormVisible(false)}
          onUserCreated={handleUserCreated}
        />
      </ModalWrapper>
      <ModalWrapper isOpen={Boolean(editEtablissement)} closeModal={() => setEditEtablissement(null)}>
        {editEtablissement && (
          <EtablissementEditForm
            etablissement={editEtablissement}
            onClose={() => setEditEtablissement(null)}
            onEtablissementUpdated={handleEtablissementUpdated}
          />
        )}
      </ModalWrapper>
      <ModalWrapper isOpen={Boolean(editUser)} closeModal={() => setEditUser(null)}>
        {editUser && (
          <UserEditForm
            user={editUser}
            onClose={() => setEditUser(null)}
            onUserUpdated={handleUserUpdated}
          />
        )}
      </ModalWrapper>
      {confirmDialog && (
        <ConfirmDialog
          message={confirmDialog.message} 
          onConfirm={confirmDialog.onConfirm}
          onCancel={() => setConfirmDialog(null)}
        />
      )}
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification(null)}
        />
      )}
      <div className="etablissement-selection">
        <label htmlFor="etablissement-select">Sélectionner un établissement:</label>
        <select id="etablissement-select" onChange={handleEtablissementSelect} className="etablissement-dropdown">
          <option value="">--Choisissez un établissement--</option>
          {etablissements.map(etab => (
            <option key={etab.id} value={etab.id}>
              {etab.nom}
            </option>
          ))}
        </select>
      </div>
      {selectedEtablissement && (
        <div>
          <h3>Événements pour {selectedEtablissement.nom}</h3>
          <EventList etablissementId={selectedEtablissement.id} canModify={true} />
        </div>
      )}
      <h2>Liste des Établissements</h2>
      <table className="admin-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Établissement</th>
            <th>Code Secret</th>
            <th>Administrateur</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {etablissements.map((etab) => (
            <tr key={etab.id}>
              <td>{etab.id}</td>
              <td>{etab.nom}</td>
              <td>{etab.code_secret}</td>
              <td>
                {users.filter(user => user.etablissementId === etab.id && user.grade === 'admin').map(admin => (
                  <div key={admin.id}>
                    <span>{admin.username}</span>
                    <button className="header-button delete-button" onClick={() => setConfirmDialog({ message: 'Êtes-vous sûr de vouloir supprimer cet administrateur ?', onConfirm: () => handleDeleteUser(admin.id) })}>Supprimer</button>
                  </div>
                ))}
              </td>
              <td>
                <button className="header-button" onClick={() => setEditEtablissement(etab)}>Modifier</button>
                <button className="header-button delete-button" onClick={() => setConfirmDialog({ message: 'Êtes-vous sûr de vouloir supprimer cet établissement ?', onConfirm: () => handleDeleteUser(etab.id) })}>Supprimer</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <h2>Liste des Utilisateurs</h2>
      <SearchBar placeholder="Rechercher un utilisateur" onChange={handleSearchChange} />
      <table className="user-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nom d'utilisateur</th>
            <th>Grade</th>
            <th>Établissement ID</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentUsers.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.username}</td>
              <td>{user.grade}</td>
              <td>{user.etablissementId}</td>
              <td>
                <button className="header-button" onClick={() => setEditUser(user)}>Modifier</button>
                <button className="header-button delete-button" onClick={() => setConfirmDialog({ message: 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?', onConfirm: () => handleDeleteUser(user.id) })}>Supprimer</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default Administration;
