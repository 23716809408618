import axios from 'axios';

const API_BASE_URL = 'https://mrjaremplacement.fr/api';

export const getAllUsers = async () => {
  return await axios.get(`${API_BASE_URL}/users/all`);
};

export const loginUser = async (credentials) => {
  try {
    console.log('Tentative de connexion avec les données:', credentials);
    const response = await axios.post(`${API_BASE_URL}/users/login`, credentials);
    console.log('Réponse reçue:', response.data);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la tentative de connexion:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const logoutUser = async () => {
  // La fonction logoutUser peut rester vide ou gérer d'autres aspects de la déconnexion
};

export const createEtablissement = async (etablissementData) => {
  return await axios.post(`${API_BASE_URL}/etablissements/create`, etablissementData);
};

export const getAllEtablissements = async () => {
  return await axios.get(`${API_BASE_URL}/etablissements/all`);
};

export const updateEtablissement = async (etablissementId, etablissementData) => {
  return await axios.put(`${API_BASE_URL}/etablissements/update/${etablissementId}`, etablissementData);
};

export const deleteEtablissement = async (etablissementId) => {
  return await axios.delete(`${API_BASE_URL}/etablissements/delete/${etablissementId}`);
};

export const deleteUser = async (userId) => {
  return await axios.delete(`${API_BASE_URL}/users/delete/${userId}`);
};

export const registerUser = async (userData) => {
  return await axios.post(`${API_BASE_URL}/users/register`, userData);
};

export const updateUser = async (userId, userData) => {
  return await axios.put(`${API_BASE_URL}/users/update/${userId}`, userData);
};

export const getEventsByEtablissement = async (etablissementId) => {
  const response = await axios.get(`${API_BASE_URL}/events/etablissement/${etablissementId}`);
  console.log('Données reçues pour les événements:', response.data);
  return response;
};

export const createEvent = async (eventData) => {
  console.log('Données envoyées pour la création de l\'événement:', eventData);
  return await axios.post(`${API_BASE_URL}/events/create`, eventData);
};

export const deleteEvent = async (id) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/events/delete/${id}`);
    console.log('Événement supprimé:', response.data);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la suppression de l\'événement:', error);
    throw error;
  }
};

export const updateEvent = async (eventId, eventData) => {
  console.log('Données envoyées pour la mise à jour de l\'événement:', eventData);
  return await axios.put(`${API_BASE_URL}/events/update/${eventId}`, eventData);
};

export const createReservation = async (eventId, reservationData) => {
  return await axios.post(`${API_BASE_URL}/events/reserve/${eventId}`, reservationData);
};

export const getReservationsByEvent = async (eventId) => {
  return await axios.get(`${API_BASE_URL}/events/${eventId}/reservations`);
};
