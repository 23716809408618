import React, { useState, useEffect } from 'react';
import { getAllEtablissements } from '../api';
import './EtablissementSelection.css';

const EtablissementSelection = ({ onEtablissementSelected }) => {
  const [userEtablissements, setUserEtablissements] = useState([]);

  useEffect(() => {
    const fetchEtablissements = async () => {
      try {
        const response = await getAllEtablissements();
        const user = JSON.parse(localStorage.getItem('user'));
        
        // Vérifiez si l'utilisateur a un établissement associé
        if (user && user.grade === 'admin' && user.etablissementId) {
          const filteredEtablissements = response.data.filter(etab =>
            etab.id === user.etablissementId
          );
          setUserEtablissements(filteredEtablissements);
        } else {
          setUserEtablissements(response.data); // Afficher tous les établissements pour les superadmins
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des établissements:', error);
      }
    };

    fetchEtablissements();
  }, []);

  const getRandomLightColor = () => {
    const letters = 'BCDEF'; // Couleurs claires
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  };

  return (
    <div className="etablissement-selection">
      <h2>Choisissez un Établissement</h2>
      <div className="etablissement-grid">
        {userEtablissements.map((etablissement) => (
          <div
            key={etablissement.id}
            className="etablissement-card"
            style={{ backgroundColor: getRandomLightColor() }}
            onClick={() => onEtablissementSelected(etablissement)}
          >
            {etablissement.nom}
          </div>
        ))}
      </div>
    </div>
  );
};

export default EtablissementSelection;
