import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './components/Header';
import LoginForm from './components/LoginForm';
import ModalWrapper from './components/ModalWrapper';
import EtablissementSelection from './components/EtablissementSelection';
import Administration from './components/Administration';
import FullCalendar from './components/FullCalendar';
import EventList from './components/EventList';
import { loginUser } from './api';
import './App.css';
import './components/FormStyles.css';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userGrade, setUserGrade] = useState('');
  const [userEtablissement, setUserEtablissement] = useState(null);
  const [scrollToEventId, setScrollToEventId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const grade = localStorage.getItem('grade');
    const etablissement = localStorage.getItem('etablissement');
    if (token && grade) {
      setIsAuthenticated(true);
      setUserGrade(grade);
      setUserEtablissement(etablissement && etablissement !== 'undefined' ? JSON.parse(etablissement) : null);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && userGrade === 'admin' && userEtablissement) {
      navigate(`/etablissement/${userEtablissement.id}`);
    }
  }, [isAuthenticated, userGrade, userEtablissement, navigate]);

  const handleLogin = async (credentials) => {
    try {
      console.log('Tentative de connexion avec les données:', credentials);
      const data = await loginUser(credentials);
      console.log('Réponse reçue:', data); // Ajoutez ce log pour voir la structure de la réponse
  
      if (data.token && data.user) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('grade', data.user.grade);
        localStorage.setItem('etablissement', JSON.stringify(data.user.etablissement || null));
        localStorage.setItem('user', JSON.stringify(data.user));
        setIsAuthenticated(true);
        setUserGrade(data.user.grade);
        setUserEtablissement(data.user.etablissement || null);
        setIsModalOpen(false);
  
        if (data.user.grade === 'admin' && data.user.etablissement) {
          navigate(`/etablissement/${data.user.etablissement.id}`);
        }
      } else {
        console.error('La réponse du serveur ne contient pas les informations attendues');
      }
    } catch (error) {
      console.error('Erreur lors de la connexion:', error.response ? error.response.data : error.message);
    }
  };
 

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('grade');
    localStorage.removeItem('etablissement');
    localStorage.removeItem('user');
    setIsAuthenticated(false);
    setUserGrade('');
    setUserEtablissement(null);
    navigate('/');
  };

  const handleEtablissementSelected = (etablissement) => {
    setUserEtablissement(etablissement);
    localStorage.setItem('etablissement', JSON.stringify(etablissement));
    navigate(`/etablissement/${etablissement.id}`);
  };

  const handleEventClick = (event) => {
    setScrollToEventId(`event-${event.id}`);
  };

  return (
    <div className="app">
      <Header
        isAuthenticated={isAuthenticated}
        onLogin={() => setIsModalOpen(true)}
        onLogout={handleLogout}
      />
      <ModalWrapper isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}>
        <LoginForm handleLogin={handleLogin} onClose={() => setIsModalOpen(false)} />
      </ModalWrapper>
      {isAuthenticated ? (
        <div>
          {userGrade === 'superadmin' && <Administration />}
          {userGrade === 'admin' && userEtablissement && (
            <div>
              <FullCalendar etablissement={userEtablissement} isAuthenticated={isAuthenticated} userGrade={userGrade} onEventClick={handleEventClick} />
              <EventList etablissementId={userEtablissement.id} canModify={true} scrollToEventId={scrollToEventId} />
            </div>
          )}
          {userGrade === 'admin' && !userEtablissement && (
            <EtablissementSelection onEtablissementSelected={handleEtablissementSelected} />
          )}
        </div>
      ) : (
        <div>
          {userEtablissement ? (
            <div>
              <FullCalendar etablissement={userEtablissement} isAuthenticated={isAuthenticated} userGrade={userGrade} onEventClick={handleEventClick} />
              <EventList etablissementId={userEtablissement.id} canModify={false} scrollToEventId={scrollToEventId} />
              <button className="header-button return-button" onClick={() => setUserEtablissement(null)}>
                Retour
              </button>
            </div>
          ) : (
            <EtablissementSelection onEtablissementSelected={handleEtablissementSelected} />
          )}
        </div>
      )}
    </div>
  );
};

export default App;
