import React, { useState } from 'react';
import { createEtablissement } from '../api';
import './FormStyles.css';

const EtablissementCreateForm = ({ onClose, onEtablissementCreated }) => {
  const [nom, setNom] = useState('');
  const [codeSecret, setCodeSecret] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newEtablissement = { nom, code_secret: codeSecret };
      await createEtablissement(newEtablissement);
      onEtablissementCreated(newEtablissement);
      onClose();
    } catch (error) {
      console.error('Erreur lors de la création de l\'établissement:', error);
    }
  };

  return (
    <div className="form-container">
      <h2>Ajouter un Établissement</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Nom</label>
          <input
            type="text"
            value={nom}
            onChange={(e) => setNom(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Code Secret</label>
          <input
            type="text"
            value={codeSecret}
            onChange={(e) => setCodeSecret(e.target.value)}
            required
          />
        </div>
        <div className="form-actions">
          <button type="submit" className="header-button add-button">Ajouter</button>
          <button type="button" className="header-button cancel-button" onClick={onClose}>Annuler</button>
        </div>
      </form>
    </div>
  );
};

export default EtablissementCreateForm;
