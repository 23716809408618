import React from 'react';
import './ReservationList.css';

const ReservationList = ({ reservations }) => {
  return (
    <div className="reservation-list">
      <h2>Liste des Réservations</h2>
      <ul>
        {reservations.map(reservation => (
          <li key={reservation.id}>
            {reservation.firstName} {reservation.lastName} - {reservation.phoneNumber}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ReservationList;
