// src/components/Header.js
import React from 'react';
import './Header.css';

const Header = ({ isAuthenticated, onLogin, onLogout }) => {
  return (
    <header className="header">
      <h1 className="header-title">MRJA Remplacement</h1>
      {isAuthenticated ? (
        <button className="header-button logout-button" onClick={onLogout}>
          Déconnexion
        </button>
      ) : (
        <button className="header-button login-button" onClick={onLogin}>
          Connexion
        </button>
      )}
    </header>
  );
};

export default Header;
