import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getEventsByEtablissement } from '../api';
import 'moment/locale/fr';
import './FullCalendar.css';

moment.locale('fr');
const localizer = momentLocalizer(moment);

const FullCalendar = ({ etablissement, isAuthenticated = false, userGrade = '', onEventClick }) => {
  const [events, setEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredEvents, setFilteredEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await getEventsByEtablissement(etablissement.id);
        const formattedEvents = response.data.flatMap(event => {
          const mainEvent = {
            title: event.title,
            start: new Date(`${event.startDate.split('T')[0]}T${event.startTime}`),
            end: new Date(`${event.endDate.split('T')[0]}T${event.endTime}`),
            id: event.id,
            allDay: false,
            className: 'main-event' // Classe CSS pour l'événement principal
          };

          const eventsWithBreak = [mainEvent];

          // Ajouter la coupure si elle existe
          if (event.startDate2 && event.startTime2 && event.endDate2 && event.endTime2) {
            const breakEvent = {
              title: `${event.title} (Reprise)`,
              start: new Date(`${event.startDate2.split('T')[0]}T${event.startTime2}`),
              end: new Date(`${event.endDate2.split('T')[0]}T${event.endTime2}`),
              id: `${event.id}-break`,
              allDay: false,
              className: 'break-event' // Classe CSS pour la coupure
            };
            eventsWithBreak.push(breakEvent);
          }

          return eventsWithBreak;
        });

        setEvents(formattedEvents);
        setFilteredEvents(formattedEvents);
      } catch (error) {
        console.error('Erreur lors de la récupération des événements:', error);
      }
    };
    fetchEvents();
  }, [etablissement.id]);

  useEffect(() => {
    const filtered = events.filter(event =>
      event.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredEvents(filtered);
  }, [searchTerm, events]);

  return (
    <div className="full-calendar">
      <h2>Calendrier pour {etablissement.nom}</h2>
      <div className="search-container">
        <input
          type="text"
          className="search-bar"
          placeholder="Rechercher des événements"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
      </div>
      {filteredEvents.length === 0 ? (
        <p>Aucun événement prévu pour cette période.</p>
      ) : (
        <Calendar
          localizer={localizer}
          events={filteredEvents}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          views={['month', 'week', 'day', 'agenda']}
          eventPropGetter={(event) => {
            const eventStyle = {
              style: {
                backgroundColor: event.className === 'break-event' ? '#FFCC00' : '#0b104e', // Orange pour les coupures, bleu foncé pour les principaux
                color: 'white',
                borderRadius: '5px',
                border: 'none',
              }
            };
            return eventStyle;
          }}
          messages={{
            next: "Suivant",
            previous: "Précédent",
            today: "Aujourd'hui",
            month: "Mois",
            week: "Semaine",
            day: "Jour",
            agenda: "Agenda",
            date: "Date",
            time: "Heure",
            event: "Événement",
            showMore: total => `+ ${total} événement(s) supplémentaire(s)`
          }}
          onSelectEvent={onEventClick}
        />
      )}
    </div>
  );
};

export default FullCalendar;
