import React, { useState } from 'react';
import './FormStyles.css';

const ReservationForm = ({ onSubmit, onClose }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [codeSecret, setCodeSecret] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ firstName, lastName, phoneNumber, codeSecret });
  };

  return (
    <div className="form-container">
      <h2>Réservation de créneau</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Prénom</label>
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Nom</label>
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Numéro de téléphone</label>
          <input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Code Secret</label>
          <input
            type="password"
            value={codeSecret}
            onChange={(e) => setCodeSecret(e.target.value)}
            required
          />
        </div>
        <div className="form-actions">
          <button type="submit" className="header-button add-button">Réserver</button>
          <button type="button" className="header-button cancel-button" onClick={onClose}>Annuler</button>
        </div>
      </form>
    </div>
  );
};

export default ReservationForm;
