import React, { useState, useEffect } from 'react';
import { getEventsByEtablissement, createReservation, getReservationsByEvent, deleteEvent, updateEvent, createEvent } from '../api';
import ModalWrapper from './ModalWrapper';
import ReservationForm from './ReservationForm';
import ReservationList from './ReservationList';
import EventForm from './EventForm';
import moment from 'moment';
import './EventList.css';

const EventList = ({ etablissementId, canModify, scrollToEventId, userGrade }) => {
  const [events, setEvents] = useState([]);
  const [isReservationModalOpen, setIsReservationModalOpen] = useState(false);
  const [isReservationListModalOpen, setIsReservationListModalOpen] = useState(false);
  const [isEventFormOpen, setIsEventFormOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [reservations, setReservations] = useState([]);
  const [notification, setNotification] = useState('');

  useEffect(() => {
    if (etablissementId !== null) {
      const fetchEvents = async () => {
        try {
          const response = await getEventsByEtablissement(etablissementId);
          const eventsWithReservations = await Promise.all(
            response.data.map(async (event) => {
              const reservationsResponse = await getReservationsByEvent(event.id);
              return {
                ...event,
                hasReservations: reservationsResponse.data.length > 0
              };
            })
          );
          setEvents(eventsWithReservations);
        } catch (error) {
          console.error('Erreur lors de la récupération des événements:', error);
        }
      };

      fetchEvents();
    }
  }, [etablissementId]);

  useEffect(() => {
    if (scrollToEventId) {
      const element = document.getElementById(scrollToEventId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [scrollToEventId]);

  const handleReservationClick = (event) => {
    setSelectedEvent(event);
    setIsReservationModalOpen(true);
  };

  const handleReservationSubmit = async (reservationData) => {
    try {
      const response = await createReservation(selectedEvent.id, reservationData);
      if (response.data.success) {
        setIsReservationModalOpen(false);
        setNotification('Réservation réussie !');
        setTimeout(() => setNotification(''), 3000); // Notification disparaît après 3 secondes
      } else {
        setNotification('Code secret incorrect');
        setTimeout(() => setNotification(''), 3000); // Notification disparaît après 3 secondes
      }
    } catch (error) {
      console.error('Erreur lors de la création de la réservation:', error);
    }
  };

  const handleViewReservationsClick = async (event) => {
    try {
      const response = await getReservationsByEvent(event.id);
      setReservations(response.data);
      setSelectedEvent(event);
      setIsReservationListModalOpen(true);
    } catch (error) {
      console.error('Erreur lors de la récupération des réservations:', error);
    }
  };

  const handleDeleteEvent = async (eventId) => {
    try {
      await deleteEvent(eventId);
      setEvents(events.filter(event => event.id !== eventId));
      setNotification('Événement supprimé avec succès');
      setTimeout(() => setNotification(''), 3000); // Notification disparaît après 3 secondes
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'événement:', error);
    }
  };

  const handleEditEventClick = (event) => {
    setSelectedEvent(event);
    setIsEventFormOpen(true);
  };

  const handleEventUpdate = async (updatedEvent) => {
    try {
      const response = await updateEvent(selectedEvent.id, updatedEvent);
      setEvents(events.map(event => (event.id === selectedEvent.id ? response.data : event)));
      setIsEventFormOpen(false);
      setNotification('Événement modifié avec succès');
      setTimeout(() => setNotification(''), 3000); // Notification disparaît après 3 secondes
    } catch (error) {
      console.error('Erreur lors de la modification de l\'événement:', error);
    }
  };

  const handleCreateEventClick = () => {
    setSelectedEvent(null);
    setIsEventFormOpen(true);
  };

  const handleEventCreate = async (newEvent) => {
    try {
      const response = await createEvent({ ...newEvent, etablissementId });
      setEvents([...events, response.data]);
      setIsEventFormOpen(false);
      setNotification('Événement créé avec succès');
      setTimeout(() => setNotification(''), 3000); // Notification disparaît après 3 secondes
    } catch (error) {
      console.error('Erreur lors de la création de l\'événement:', error);
    }
  };

  const formatDuration = (start, end) => {
    if (!start || !end) return 'Durée non définie';
    const startDateTime = moment(start);
    const endDateTime = moment(end);
    const duration = moment.duration(endDateTime.diff(startDateTime));
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    return `${hours}h ${minutes}m`;
  };

  const calculateTotalDuration = (event) => {
    const duration1 = formatDuration(
      combineDateTime(event.startDate, event.startTime),
      combineDateTime(event.endDate, event.endTime)
    );

    const duration2 = event.startDate2 && event.startTime2
      ? formatDuration(
          combineDateTime(event.startDate2, event.startTime2),
          combineDateTime(event.endDate2, event.endTime2)
        )
      : null;

    if (duration2) {
      const [hours1, minutes1] = duration1.split('h').map(str => parseInt(str.trim()));
      const [hours2, minutes2] = duration2.split('h').map(str => parseInt(str.trim()));
      const totalHours = hours1 + hours2;
      const totalMinutes = minutes1 + minutes2;
      return `${totalHours + Math.floor(totalMinutes / 60)}h ${totalMinutes % 60}m`;
    }

    return duration1;
  };

  const combineDateTime = (date, time) => {
    return moment(`${date.split('T')[0]}T${time}`);
  };

  return (
    <div className="event-list">
      <h2>Liste des Événements</h2>
      {notification && <p className="notification">{notification}</p>}
      <div className="event-cards">
        {events.map(event => (
          <div className="event-card" key={event.id} id={`event-${event.id}`}>
            <strong>{event.title}</strong>
            {userGrade === 'admin' || userGrade === 'super_admin' ? (
              event.hasReservations ? <span className="reservation-badge">🔴</span> : null
            ) : null}
            <br />
            <div>À partir du {moment(event.startDate).format('DD/MM/YYYY')}</div>
            <div>de {event.startTime} à {event.endTime}</div>
            {event.startDate2 && event.startTime2 && (
              <>
                <div>Reprise à partir du {moment(event.startDate2).format('DD/MM/YYYY')}</div>
                <div>de {event.startTime2} à {event.endTime2}</div>
              </>
            )}
            <div>Durée totale : {calculateTotalDuration(event)}</div>
            {canModify ? (
              <div className="button-group">
                <button onClick={() => handleViewReservationsClick(event)} className="header-button view-button">
                  Voir les réservations
                </button>
                <button onClick={() => handleEditEventClick(event)} className="header-button add-button">
                  Modifier
                </button>
                <button onClick={() => handleDeleteEvent(event.id)} className="header-button delete-button">
                  Supprimer
                </button>
              </div>
            ) : (
              <button onClick={() => handleReservationClick(event)} className="header-button add-button">
                Se proposer sur ce créneau
              </button>
            )}
          </div>
        ))}
        {canModify && (
          <div className="event-card add-event-card" onClick={handleCreateEventClick}>
            <span className="add-event-icon">+</span>
          </div>
        )}
      </div>

      <ModalWrapper isOpen={isReservationModalOpen} closeModal={() => setIsReservationModalOpen(false)}>
        <ReservationForm onSubmit={handleReservationSubmit} onClose={() => setIsReservationModalOpen(false)} />
      </ModalWrapper>

      <ModalWrapper isOpen={isReservationListModalOpen} closeModal={() => setIsReservationListModalOpen(false)}>
        <ReservationList reservations={reservations} />
      </ModalWrapper>

      <ModalWrapper isOpen={isEventFormOpen} closeModal={() => setIsEventFormOpen(false)}>
        <EventForm
          etablissementId={etablissementId}
          event={selectedEvent}
          onClose={() => setIsEventFormOpen(false)}
          onSave={selectedEvent ? handleEventUpdate : handleEventCreate}
        />
      </ModalWrapper>
    </div>
  );
};

export default EventList;
