import React from 'react';
import './ConfirmDialog.css';

const ConfirmDialog = ({ message, onConfirm, onCancel }) => {
  return (
    <div className="confirm-dialog">
      <p>{message}</p>
      <button className="confirm-button" onClick={onConfirm}>Confirmer</button>
      <button className="cancel-button" onClick={onCancel}>Annuler</button>
    </div>
  );
};

export default ConfirmDialog;
